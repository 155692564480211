<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Name</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="holiday.name"
                  placeholder="Enter name"
                  required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Start Date</label>
              <el-date-picker
                  @change="updateDuration"
                  required
                  class="w-100 p-0"
                  v-model="holiday.start_date"
                  type="date"
                  placeholder="Enter start date"
                  :format="$datePickerFormat"
                  value-format="yyyy-MM-dd"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>End Date</label>
              <el-date-picker
                  @change="updateDuration"
                  required
                  class="w-100 p-0"
                  v-model="holiday.end_date"
                  type="date"
                  placeholder="Enter end date"
                  :format="$datePickerFormat"
                  value-format="yyyy-MM-dd"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Duration</label>
              <input
                  readonly
                  type="text"
                  class="form-control"
                  v-model="holiday.duration"
                  required
              />
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: "CreateHoliday",
  data: () => {
    return {
      showToast: false,
      toastr: "",
      holiday: {
        name: "",
        start_date: "",
        end_date: "",
        duration: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    updateDuration() {
      const startDate = new Date(this.holiday.start_date);
      const endDate = new Date(this.holiday.end_date);

      // Calculate the duration in days
      const durationInDays = Math.floor(
          (endDate - startDate) / (24 * 60 * 60 * 1000)
      );

      // Update the duration in the data model
      const durationInDaysCount = durationInDays >= 0 ? durationInDays + 1 : 0;
      this.holiday.duration =
          durationInDaysCount > 0
              ? `${durationInDaysCount} ${durationInDaysCount > 1 ? 'Days' : 'Day'}`
              : '0 Day';
    },
    onSubmitted() {
      this.$store
          .dispatch("Holiday/storeHoliday", this.holiday)
          .then(() => {
            this.showToast = true;
            this.$toastr.s('success', "Holiday successfully created.", "Holiday Created");
            setTimeout(() => {
              this.$emit("onSavedHoliday");
            }, 350);
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }

            let errors = err.response.data.errors;
            this.showToast = true;
            for (let field of Object.keys(errors)) {
              this.$toastr.e("error", errors[field][0], "Error!");
            }
          });
    },
  }
};
</script>

<style scoped>
</style>
